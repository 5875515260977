import GenericService from "./generic"
import { capitalizeWords } from "../utils"

const SERVICE_NAME = "contract"

class ContractService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
	}

	async getCurrentContractInformation(contractID) {
		const response = await this.axios.get("/my-account/contract/properties", {
			params: {
				contract_id: contractID,
			},
		})

		return response.contractsProperty.map((contract) => {
			contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
			contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
			contract.property.city = capitalizeWords(contract.property.city)
			return contract
		})
	}

	async lookupContract(searchFields) {
		const response = await this.axios.post("/my-account/contract/search", searchFields)

		return response.contracts.map((contract) => {
			contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
			contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
			contract.property.city = capitalizeWords(contract.property.city)
			return contract
		})
	}

	getContractDocument(contractID) {
		return this.axios.get(`/my-account/v2/contract/${contractID}/document`)
	}

	fetchRenewalOrUpgradeEligibility(contractID) {
		return this.axios.get(`/my-account-renewals/${contractID}/renewal-or-upgrade`)
	}
}

export default new ContractService()
