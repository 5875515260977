import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ContractService from "../../services/contract"
import { PaymentActions } from "../payment/actions"
import { setCustomerID } from "../../analytics/user.analytics"
import { getContractCustomerID } from "../../utils"
import Cookies from "universal-cookie"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchContractsStart = actionsBuilder.createAction("fetchContractsStart", (state) => {
	state[namespace].loading = true
})

export const fetchContractsEnd = actionsBuilder.createAction("fetchContractsEnd", (state, { contracts, currentContract }) => {
	if (contracts && contracts.length) {
		state[namespace].list = contracts
		state[namespace].current = currentContract
	}

	state[namespace].loading = false
})

export const fetchContractsError = actionsBuilder.createAction("fetchContractsError", (state) => {
	state[namespace].loading = false
	state[namespace].fetchError = true
})

export const setCurrentContract = actionsBuilder.createAction("setCurrentContract", (state, contractID) => {
	const contract = state[namespace].list.find((contract) => contract.contractID === contractID)

	state[namespace].current = contract
})

export const setDownloadingContract = actionsBuilder.createAction("setDownloadingContract", (state, { loading }) => {
	state[namespace].isDownloadingContract = loading
})

export const addEligibilityInfo = actionsBuilder.createAction("addEligibilityInfo", (state, { eligibilityInfo, contractID }) => {
	let currentContract = state[namespace].current
	if (currentContract.contractID === contractID) {
		currentContract.eligibilityInfo = eligibilityInfo
	}
	state[namespace].current = currentContract
})

export const ContractActions = {
	fetchContracts:
		(currentContractID = undefined) =>
		async (dispatch, getState) => {
			dispatch(fetchContractsStart())

			const state = getState()

			let contracts = []
			let currentContract = null

			if (!currentContractID) {
				currentContractID = window.sessionStorage.getItem("RequestServiceSetContract")

				if (currentContractID) {
					window.sessionStorage.removeItem("RequestServiceSetContract")
				} else {
					const cookies = new Cookies()
					//get the FusionAuth access token from the cookie.
					currentContractID = cookies.get("currentContractID")
				}
			}

			try {
				contracts = await ContractService.getCurrentContractInformation(currentContractID)
			} catch (e) {
				console.log("Failed to fetch contract information.")

				dispatch(fetchContractsError())
				return false
			}

			currentContract = contracts.find((contract) => contract.contractID === currentContractID) || contracts[0]

			// Set the current contract to be remembered
			window.sessionStorage.setItem("currentContractID", currentContract.contractID)

			dispatch(fetchContractsEnd({ contracts, currentContract }))

			if (currentContract) {
				setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, currentContract.contractID))
			}

			dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
			return true
		},

	setCurrentContract: (contractID) => async (dispatch, getState) => {
		const state = getState()

		// Set the current contract to be remembered
		window.sessionStorage.setItem("currentContractID", contractID)

		if (state.user.isLoggedIn && state.contract.list.some((contract) => contract.contractID === contractID)) {
			dispatch(setCurrentContract(contractID))
			setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, contractID))
			dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
		}
	},

	downloadContract: (contractID) => async (dispatch) => {
		dispatch(setDownloadingContract({ loading: true }))
		return ContractService.getContractDocument(contractID)
			.then((response) => {
				window.open(response.link, "_blank")
			})
			.finally(() => dispatch(setDownloadingContract({ loading: false })))
	},

	getEligibilityInfo: (contractID) => async (dispatch) => {
		const eligibilityInfo = await ContractService.fetchRenewalOrUpgradeEligibility(contractID)

		dispatch(addEligibilityInfo({ eligibilityInfo, contractID }))
	},
}

export const actions = actionsBuilder.exportActions()
